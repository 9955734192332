<template>
  <div class="popup-form">
    <HeadSection :title="isEditMode ? `Item #${popupId}` : 'Create new pop-up banner'"
      permissionOfSection="popup-banners" :breadcrumbs="breadcrumbs">
      <template #activeSwitch>
        <div class="ml-4">
          <a-switch v-model="form.isActive" />
        </div>
      </template>
      <template #actions>
        <a-col :span="14" class="text-right d-flex justify-content-end">
          <div v-if="isEditMode">
            <a-popconfirm title="Sure to delete?" placement="bottom" @confirm.stop="deletePopup">
              <a-button :loading="loading" type="danger"> Delete </a-button>
            </a-popconfirm>
            <a-button :loading="loading" type="primary" class="ml-4" @click="save">
              Save
            </a-button>
          </div>
          <div v-else class="pt-4">
            <a-button class="ml-4" @click="clearForm" :loading="loading">
              Clear
            </a-button>
            <a-button :loading="loading" type="primary" class="ml-4" @click="save">
              Save
            </a-button>
          </div>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer :loading="loading || userLoading" permissionOfSection="popup-banners">
      <a-form-model :rules="rules" :model="form" ref="form">
        <div class="d-flex flex-column popup-form">
          <span class="font-weight-bold font-size-16">General</span>
          <a-row>
            <a-col :span="7">
              <div class="d-flex flex-column">
                <div class="mb-2">
                  <span class="title required-label mr-2">Tech name</span>
                  <a-tooltip slot="suffix" title="Visible only in admin panel">
                    <a-icon type="info-circle" class="text-primary" />
                  </a-tooltip>
                </div>
                <a-form-model-item prop="popupName" :colon="false" style="max-width: 427px;">
                  <a-input v-model="form.popupName" placeholder="Name" size="large" :disabled="loading" />
                </a-form-model-item>
              </div>
            </a-col>
          </a-row>
          <div class="d-flex flex-row align-items-start" style="gap: 1rem">
            <a-form-model-item label="Start date" prop="startDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.startDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" :disabled-date="disableDatesBeforeToday" />
            </a-form-model-item>
            <a-form-model-item label="End date" prop="endDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.endDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" :disabled-date="disabledEndDate" />
            </a-form-model-item>
          </div>
          <a-row>
            <a-col :span="3">
              <a-form-model-item label="Show limit" prop="showLimit" :colon="false">
                <a-input-number v-model="form.showLimit" :min="1" size="large" :disabled="loading" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item prop="popupSegments" :colon="false" style="max-width: 427px;">
                <template #label>
                  <span class="mr-4">Segments</span>
                  <a @click.stop="openAddSegmentsModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                    Add</a>
                </template>
                <div class="d-flex align-items-center">
                  <a-select :disabled="loading" v-model="form.popupSegments" mode="multiple" size="large">
                    <template #placeholder><span style="color: #a1a1c2">Choose segments</span></template>
                    <a-select-option v-for="segment in segments" :key="segment.id" :value="segment.id">
                      {{ segment.name }}
                    </a-select-option>
                  </a-select>
                  <!-- <a v-if="!!form.popupSegment && isSegmentEditable" @click.stop="openEditSegmentModal" class="ml-3"
                    style="color: rgb(0, 123, 255) !important; font-weight: 400">Edit</a> -->
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="2">
              <a-form-model-item label="Display section" prop="popupDisplays" :colon="false">
                <a-select v-model="form.popupDisplays" mode="multiple" size="large" disabled allow-clear
                  style="max-width: 200px;">
                  <a-select-option v-for="display in displays" :key="display.value">
                    {{ display.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item prop="popupApps" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Apps</span>
              <a @click.stop="openAddAppModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+ Add</a>
            </template>
            <div v-if="form.popupApps">
              <a-tag style="
                    margin-right: 15px;
                    padding: 1px 8px;
                    border-radius: 2px;
                  " v-for="app in form.popupApps.slice(0, 5)" :key="app.id">
                {{ app.name }}
                <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
              </a-tag>
              <a-tag v-if="form.popupApps.length > 4"> ... </a-tag>
            </div>
          </a-form-model-item>
          <a-divider class="m-0 mb-3" />
          <div>
            <div class="d-flex flex-row align-items-center justify-content-between mb-2">
              <span class="font-weight-bold font-size-16 required-label">Media</span>
              <div class="checkbox-container">
                <a-button type="link" :disabled="!isApplyToAllAvailable" @click="addToAllLangs">
                  Apply to all languages
                </a-button>
              </div>
            </div>
            <div>
              <a-tabs v-if="languages.length > 1" :default-active-key="languages[0].name" :active-key="selectedLanguage"
                @change="handleTabChange" :tabBarStyle="{ border: 'none' }" class="mb-2">
                <a-tab-pane v-for="language in languages" :key="language.name" :tab="language.desc" />
              </a-tabs>
              <div class="images mb-4">
                <div class="d-flex flex-column">
                  <span class="mb-2 title required-label">Image</span>
                  <div>
                    <div>
                      <a-upload list-type="picture-card" class="uploader main" :show-upload-list="false"
                        @change="handleImageUpload" :disabled="loading">
                        <div>
                          <div v-if="selectedImage.url" class="popup-image">
                            <img :src="selectedImage.url" />
                          </div>
                          <template v-else>
                            <a-icon :type="selectedImage.loading ? 'loading' : 'inbox'" class="text-primary"
                              style="font-size: 48px" />
                            <div>
                              Upload<br />
                              <div class="text mt-1">
                                File size 1400 x 2200 px
                              </div>
                            </div>
                          </template>
                        </div>
                      </a-upload>
                    </div>
                  </div>
                </div>
              </div>
              <a-row class="mb-4">
                <a-col :span="7">
                  <div class="d-flex flex-column">
                    <div>
                      <span class="title required-label mr-2">Banner theme</span>
                      <a-tooltip slot="suffix" title="Select text color depending on the image background">
                        <a-icon type="info-circle" class="text-primary" />
                      </a-tooltip>
                    </div>
                    <a-radio-group name="radioGroup" v-model="selectedInfo.theme" class="mt-2">
                      <a-radio value="light"> Light </a-radio>
                      <a-radio value="dark"> Dark </a-radio>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
              <div>
                <a-row>
                  <a-col :span="7">
                    <a-form-model-item :colon="false" style="max-width: 427px;">
                      <template #label><span class="required-label">Title</span></template>
                      <a-textarea v-model="selectedInfo.title" placeholder="Enter title"
                        :autoSize="{ minRows: 1, maxRows: 4 }" :showCount="true" :maxLength="100" :disabled="loading"
                        style="min-height: 40px; font-size: 16px; padding: 8px 11px;" />
                      <div class="text-count">
                        <span>{{ selectedTitleLength }} / 100</span>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8">
                    <a-form-model-item :colon="false" style="max-width: 427px;">
                      <template #label><span class="required-label">Text area</span></template>
                      <a-textarea v-model="selectedInfo.description" placeholder="Enter text"
                        :autoSize="{ minRows: 1, maxRows: 4 }" :showCount="true" :maxLength="250" :disabled="loading"
                        style="min-height: 40px; font-size: 16px; padding: 8px 11px;" />
                      <div class="text-count">
                        <span>{{ selectedTextAreaLength }} / 250</span>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </div>
            </div>
            <div>
              <div class="d-flex flex-row align-items-center mb-2">
                <div>
                  <span class="font-weight-bold font-size-14 mr-3">Redirect</span>
                  <a v-if="!selectedRedirect.length" @click.stop="handleAddRedirect"
                    style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                    Add</a>
                  <a-icon v-else type="delete" @click="handleClearRedirect" style="
                    color: red;
                    cursor: pointer;
                    margin-left: 8px;
                    font-size: 16px;" />
                </div>
              </div>
              <div v-for="(redirect, redirectIndex) in selectedRedirect" :key="redirectIndex" class="mb-4">
                <div class="d-flex flex-column redirect-list">
                  <div class="d-flex flex-row align-items-center mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item :colon="false">
                      <template #label><span class="required-label">Name</span></template>
                      <a-input placeholder="Name" :disabled="loading" v-model="redirect.buttonName"
                        style="max-width: 200px" size="large">
                        <template #placeholder><span style="color: #a1a1c2">Name</span></template>
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="buttonColor" :colon="false">
                      <template #label><span>Button color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.buttonColor"
                        style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="fontColor" :colon="false">
                      <template #label><span>Font color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.fontColor"
                        style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="d-flex flex-row align-items-end mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item>
                      <template #label><span class="required-label">Link type</span></template>
                      <a-select v-model="redirect.linkType" size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2">Select link type</span></template>
                        <a-select-option value="custom"> Custom </a-select-option>
                        <a-select-option value="sport"> Sport </a-select-option>
                        <a-select-option value="match"> Match </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <div v-if="redirect.linkType === 'custom'" class="d-flex flex-ro align-items-end">
                      <a-form-model-item :colon="false" class="mr-3" style="min-width: 416px">
                        <template #label><span class="required-label">Link</span></template>
                        <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                          v-model="redirect.link" size="large">
                        </a-input>
                      </a-form-model-item>
                      <!-- TODO WIDTH -->
                      <a-button type="primary" size="large" class="mr-4" :disabled="loading"
                        @click="openAddLinkTemplatesModal">
                        Create new
                      </a-button>
                      <a-form-model-item label="Select template">
                        <a-select v-model="redirect.id" @change="changeRedirectTemplate(redirect.id)" size="large"
                          :disabled="loading" allow-clear>
                          <template #placeholder><span style="color: #a1a1c2">Template</span></template>
                          <a-select-option v-for="linkTemplate in linkTemplates" :key="linkTemplate.id">
                            {{ linkTemplate.name }}
                            <span style="color: #a1a1c2;">[{{ linkTemplate.link }}]</span>
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </div>
                    <a-form-model-item v-if="redirect.linkType === 'match'" :colon="false" class="mr-3"
                      style="min-width: 416px">
                      <template #label><span class="required-label">Match link</span></template>
                      <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                        v-model="redirect.link" size="large">
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item v-if="redirect.linkType === 'sport'">
                      <template #label><span class="required-label">Select sport</span></template>
                      <a-select v-model="redirect.sportId" size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2;">Select sport</span></template>
                        <a-select-option v-for="sport in sports" :key="sport.sport_id">
                          {{ sport.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <div v-if="!errors.oneFullPopup" style="color: red; margin-top: 8px;">At least one language should have image,
        title and description</div>
      <div v-if="!errors.noInvalidPopup" style="color: red; margin-top: 8px">Can't create pop-up banner with image but
        without title & text area and vice versa</div>
      <div v-if="!errors.isRedirectValid" style="color: red; margin-top: 8px">Redirect must have name, link type and
        link / sport itself</div>
      <div v-if="!errors.redirectColorLength" style="color: red; margin-top: 8px">Redirect color inputs can't be longer
        than 7 chars</div>
    </MainContainer>
    <a-modal v-model="addAppsModal" title="Add new apps" :footer="null" width="700px" :destroyOnClose="true">
      <AppsModal @selectApps="changeSelectedApps" @close="closeAddAppsModal" :modalAppsData="modalAppsData" />
    </a-modal>
    <a-modal v-model="addLinkTemplateModal" width="395px" :destroyOnClose="true" @ok="handleAddTemplatesModal">
      <template #title><span class="modal-title">Add new template</span></template>
      <a-form-model :model="addTemplateForm" ref="templateForm" :rules="templateRules">
        <a-form-model-item label="Name" prop="name" :colon="false">
          <a-input placeholder="Name" :disabled="loading" size="large" v-model="addTemplateForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Link" prop="link" :colon="false">
          <a-input placeholder="https://www.olimp.bet/line/1/5956496/7848761" :disabled="loading" size="large"
            v-model="addTemplateForm.link"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- <a-modal v-model="addUserModal" :footer="null" width="450px" :destroyOnClose="true">
      <template #title>
        <span class="modal-title">New user</span>
      </template>
      <UserModal :segment-id="form.popupSegment" @closeUserModal="closeUserModal" @addNewUser="handleAddUser" />
    </a-modal> -->
    <a-modal v-model="addSegmentsModal" title="Add segment" :footer="null" width="450px" :destroyOnClose="true">
      <SegmentsModal @closeModalFunction="closeAddSegmentsModal" @addNewSegment="handleAddSegment" />
    </a-modal>
    <!-- <a-modal v-model="editSegmentModal" :footer="null" width="700px" :destroyOnClose="true">
      <template #title>
        <span class="modal-title mr-3">{{ editSegmentTitle }}</span>
        <span class="user-count">{{ editSegmentUserCount }} users</span>
      </template>
      <EditSegmentModal @closeEditSegmentModal="closeEditSegmentModal" @userModal="openAddUserModal"
        :segment-id="form.popupSegment" />
    </a-modal> -->
  </div>
</template>

<script>
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import AppsModal from '@/components/custom/modals/appsModal.vue'
import SegmentsModal from '@/components/custom/modals/segmentsModal.vue'
// import EditSegmentModal from '@/views/apps/viewscreens/modals/editSegmentModal.vue'
// import UserModal from '@/components/custom/modals/userModal.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'
import PopupService from '@/services/api/apiPopupService'
import apiClient from '@/services/axios'
import { mapState } from 'vuex'

const initialForm = {
  popupName: '',
  popupSegments: [],
  isActive: true,
  startDate: null,
  endDate: null,
  showLimit: null,
  popupDisplays: [4],
  popupApps: [],
}
const defaultRedirect = {
  buttonName: '',
  buttonColor: '',
  fontColor: '',
  linkType: '',
  linkName: '',
  sportId: '',
  link: '',
  id: '',
}

const defaultPopupImg = {
  id: null,
  url: '',
  file: null,
  loading: false,
}
const defaultPopupInfo = {
  title: '',
  description: '',
  theme: 'light',
}

const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

function getImageDimensions(file) {
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  })
}

function getBase64(img) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })
}

export default {
  name: 'popupForm',
  components: { AppsModal, SegmentsModal, HeadSection, MainContainer }, // EditSegmentModal, UserModal
  data() {
    return {
      form: { ...initialForm },
      content: {
        ru: {
          popupImage: {
            ...defaultPopupImg,
          },
          popupInfo: {
            ...defaultPopupInfo,
          },
          redirect: [],
        },
      },
      errors: {
        oneFullPopup: true,
        noInvalidPopup: true,
        isRedirectValid: true,
        redirectColorLength: true,
      },
      tags: [
        { name: 'new', icon: 'NewIcon' },
        { name: 'promo', icon: 'PromoIcon' },
        { name: 'top', icon: 'TopIcon' },
        { name: 'tech', icon: 'TechIcon' },
        { name: 'maintenance', icon: 'MaintenanceIcon' },
        { name: 'no tag' },
      ],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      loading: false,
      addAppsModal: false,
      addUserModal: false,
      addSegmentsModal: false,
      editSegmentModal: false,
      languages: ['ru'],
      selectedLanguage: 'ru',
      linkTemplates: [],
      sports: [],
      addTemplateForm: {
        name: '',
        link: '',
      },
      segments: null,
      isRedirectAvailable: false,
      checkAllLangs: false,
      addLinkTemplateModal: false,
      popupToEdit: null,
      rules: {
        popupName: [
          {
            required: true,
            message: 'Pop-up banner name is required',
            trigger: 'blur',
          },
        ],
        popupSegments: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one segment',
            trigger: 'change',
          },
        ],
        startDate: [
          {
            required: true,
            message: 'Please pick start date',
            trigger: 'change',
          },
        ],
        endDate: [
          {
            required: true,
            message: 'Please pick end date',
            trigger: 'change',
          },
        ],
        showLimit: [
          {
            required: true,
            message: 'Show limit is required',
            trigger: 'blur',
          },
        ],
        popupDisplays: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one section',
            trigger: 'change',
          },
        ],
        popupApps: [
          {
            type: 'array',
            required: true,
            message: 'Applications are required',
            trigger: 'change',
          },
        ],
      },
      templateRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
      },
      modalAppsData: null,
    }
  },
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    isEditMode() {
      return this.$route.path.split('/').includes('edit')
    },
    routeTitle() {
      return 'Pop-up banners'
    },
    popupTitle() {
      return this.isEditMode ? 'Edit pop-up banner' : 'New pop-up banner'
    },
    selectedContent() {
      return this.content[this.selectedLanguage]
    },
    selectedInfo() {
      return this.selectedContent.popupInfo
    },
    selectedRedirect() {
      return this.selectedContent.redirect
    },
    selectedTitleLength() {
      if (this.selectedInfo.title) {
        return this.selectedInfo.title.length
      }
      return 0
    },
    selectedTextAreaLength() {
      if (this.selectedInfo.description) {
        return this.selectedInfo.description.length
      }
      return 0
    },
    selectedImage () {
      return this.selectedContent.popupImage
    },
    isApplyToAllAvailable() {
      if (this.checkIsLangEmpty(this.selectedLanguage)) {
        return false
      }

      const nonSelectedLanguages = Object.keys(this.content).filter(
        (language) => language !== this.selectedLanguage,
      )
      const emptyLanguagesCount = nonSelectedLanguages.filter((currentLang) =>
        this.checkIsLangEmpty(currentLang),
      ).length
      return emptyLanguagesCount === this.languages.length - 1
    },
    breadcrumbs() {
      return [
        { name: 'Pop-up banner', link: '/popup-banners' },
        { name: this.isEditMode ? 'Edit pop-up banner' : 'New pop-up banner', link: undefined },
      ]
    },
    // segmentIsDefault() {
    //   const defaultId = this.segments.find(segment => segment.is_default)?.id || null
    //   return this.form.popupSegment === defaultId
    // },
    // isSegmentEditable() {
    //   const selectedSegment = this.segments.find(segment => segment.id === this.form.popupSegment)
    //   return !(this.segmentIsDefault || (selectedSegment && selectedSegment.type !== 'all'))
    // },
    // editSegmentTitle() {
    //   if (!this.form.popupSegment) {
    //     return null
    //   }
    //   return this.segments.find(segment => segment.id === this.form.popupSegment).name
    // },
    // editSegmentUserCount() {
    //   if (!this.form.popupSegment) {
    //     return null
    //   }
    //   return this.segments.find(segment => segment.id === this.form.popupSegment).count
    // },
    popupId() {
      if (!this.isEditMode) {
        return null
      }
      return this.$route.params.id
    },
  },
  async created() {
    await this.fetchLocalizations()
    await this.fetchSports()
    await this.fetchLinkTemplates()
    await this.getSegments()

    if (this.isEditMode) {
      this.popupToEdit = await this.getPopup()
      this.fillForm()
    }

    if (!this.popupToEdit) {
      this.selectedLanguage = this.languages[0].name
      this.initializeContent()
    }
  },
  methods: {
    async save() {
      const isValid = await this.validateForm()
      if (!isValid) {
        return false
      }
      if (!this.isEditMode) {
        return this.createPopup()
      }
      this.updatePopup()
    },
    async validateForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })

      let isAllEmpty = true
      let isOneInvalid = false
      let allRedirectsValid = true
      let allRedirectsColorValid = true

      for (const lang in this.content) {
        const currentLang = this.content[lang]
        const isImgEmpty = !currentLang.popupImage.url
        const isTitleEmpty = !currentLang.popupInfo.title || currentLang.popupInfo.title.trim() === ''
        const isDescEmpty = !currentLang.popupInfo.description || currentLang.popupInfo.description.trim() === ''

        const isCurrentLangEmpty = isImgEmpty && isTitleEmpty && isDescEmpty
        const isCurrentLangValid = !isImgEmpty && !isTitleEmpty && !isDescEmpty

        if (isCurrentLangValid) {
          isAllEmpty = false
        }

        if (!isCurrentLangEmpty && !isCurrentLangValid) {
          isOneInvalid = true
        }

        if (currentLang.redirect.length > 0) {
          const hasInvalidRedirect = currentLang.redirect.some((redirect) => {
            const { buttonName, link, linkType, sportId } = redirect

            const fieldsToValidate = { buttonName, linkType }
            if (linkType === 'sport') {
              fieldsToValidate.sportId = sportId
            } else {
              fieldsToValidate.link = link
            }
            console.log(fieldsToValidate)
            return Object.values(fieldsToValidate).some(value => value === '' || value === undefined)
          })

          const hasInvalidColorLength = currentLang.redirect.some(({ buttonColor, fontColor }) =>
            buttonColor.length > 7 || fontColor.length > 7,
          )

          if (hasInvalidRedirect) allRedirectsValid = false
          if (hasInvalidColorLength) allRedirectsColorValid = false
        }
      }

      const errors = {
        oneFullPopup: !isAllEmpty,
        noInvalidPopup: !isOneInvalid,
        isRedirectValid: allRedirectsValid,
        redirectColorLength: allRedirectsColorValid,
      }
      this.errors = { ...errors }
      if (!isValid || Object.values(this.errors).includes(false)) {
        return false
      }
      return true
    },
    disableDatesBeforeToday(startValue) {
      const endValue = this.form.endDate
      if (!startValue || !endValue) {
        return startValue && startValue < this.$moment().add(-1, 'day').endOf('day')
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startDate
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    async validateUploadedImage(file) {
      const isImage = file.type.split('/')[0] === 'image'
      const fileExtension = file.type.split('/')[1]

      if (!isImage) {
        this.$notification.error({
          message: 'You can only upload WEBP, PNG or JPEG file!',
        })
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        this.$notification.error({
          message: 'Pop-up image must be smaller than 10MB!',
        })
        return false
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 1400 || height !== 2200) {
          this.$notification.error({
            message: 'Pop-up image must be 1400 x 2200px!',
          })
          return false
        }
        if (fileExtension !== 'png' && fileExtension !== 'webp' && fileExtension !== 'jpeg') {
          this.$notification.error({
            message: 'Pop-up image must have WEBP, PNG or JPEG extension!',
          })
          return false
        }
      }

      return file
    },
    addToAllLangs() {
      for (const language in this.content) {
        const currentLang = this.content[language]
        if (language !== this.selectedLanguage) {
          currentLang.popupInfo = deepClone(this.selectedContent.popupInfo)
          currentLang.popupImage = deepClone(this.selectedContent.popupImage)
          currentLang.redirect = deepClone(this.selectedContent.redirect)
        }
      }
    },
    checkIsLangEmpty(currentLang) {
      const currentContent = this.content[currentLang]
      if (typeof currentContent === 'undefined') {
        return false
      }
      const currentLangEmpty = currentContent.popupImage.id === null || currentContent.popupInfo.title === '' || currentContent.popupInfo.description === ''
      return currentLangEmpty
    },
    initializeContent() {
      this.content = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            popupImage: { ...defaultPopupImg },
            popupInfo: { ...defaultPopupInfo },
            redirect: [],
          },
        }
      }, {})
    },
    handleTabChange(selectedLangKey) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = selectedLangKey
    },
    handleAddRedirect() {
      this.selectedRedirect.push({ ...defaultRedirect })
    },
    handleDeleteRedirect(redirectIndex) {
      this.selectedRedirect.splice(redirectIndex, 1)
    },
    handleClearRedirect() {
      this.selectedContent.redirect = []
    },
    changeRedirectTemplate(linkId) {
      const searchTemplate = this.linkTemplates.find((template) => {
        return template.id === linkId
      })
      this.selectedRedirect[0].link = searchTemplate.link
      this.selectedRedirect[0].linkName = searchTemplate.name
    },

    async handleImageUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedImage(
        event.file.originFileObj,
      )

      if (!isFileValid) {
        return
      }

      const fileId = file.uid

      this.content[this.selectedLanguage].popupImage = {
        ...this.selectedImage,
        loading: true,
        id: fileId,
        file,
      }

      const formData = new FormData()

      formData.append('cover', file.originFileObj)

      try {
        const popupResponse = await PopupService.uploadPopupImage(file)
        const previewId = popupResponse.data?.data?.id
        if (!previewId) {
          const errorMessage =
          popupResponse.data?.error?.message ||
            'Something went wrong while uploading pop-up image'
          this.content[this.selectedLanguage].popupImage = {
            ...defaultPopupImg,
          }
          this.$notification.error({
            message: errorMessage,
          })
        }
        this.content[this.selectedLanguage].popupImage.id = previewId
        this.content[this.selectedLanguage].popupImage.url = await getBase64(
          event.file.originFileObj,
        )
        this.content[this.selectedLanguage].popupImage.loading = false
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while uploading the preview'
        this.content[this.selectedLanguage].popupImage = {
          ...defaultPopupImg,
        }
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    fillForm() {
      this.form.popupName = this.popupToEdit.name
      this.form.popupSegments = this.popupToEdit.segments ? this.popupToEdit.segments.map((segment) => {
        return segment.id
      }) : []
      this.form.popupApps = this.popupToEdit.apps
      this.form.showLimit = this.popupToEdit.limit
      this.modalAppsData = this.popupToEdit.apps
      this.form.startDate = this.$moment(this.popupToEdit.start_date).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
      this.form.endDate = this.$moment(this.popupToEdit.end_date).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
      this.form.isActive = this.popupToEdit.is_active
      this.form.popupDisplays = this.popupToEdit.displays.map((display) => {
        return display.id
      })
      const popupLanguages = Object.keys(this.popupToEdit.localizations)
      this.selectedLanguage = popupLanguages[0]
      const popupContent = popupLanguages.reduce((acc, language) => {
        const localization = this.popupToEdit.localizations[language]
        return {
          ...acc,
          [language]: {
            popupImage: {
              id: localization.cover_id,
              url: localization.path,
            },
            popupInfo: {
              title: localization.title,
              description: localization.description,
              theme: localization.theme,
            },
            redirect: localization.redirect
              ? [
                {
                  fontColor: localization.button_font_color || '',
                  buttonColor: localization.button_color || '',
                  buttonName: localization.button_name,
                  link: localization.redirect.link ?? null,
                  linkType: localization.redirect.type,
                  ...(localization.redirect.type === 'custom' && {
                    id: localization.redirect.id,
                    linkName: localization.redirect.name,
                  }),
                  ...(localization.redirect.type === 'sport' && {
                    sportId: localization.redirect.sport.sport_id,
                  }),
                },
              ]
              : [],
          },
        }
      }, {})
      const defaultContent = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            popupImage: { ...defaultPopupImg },
            popupInfo: { ...defaultPopupInfo },
            redirect: [],
          },
        }
      }, {})

      this.content = {
        ...defaultContent,
        ...popupContent,
      }
    },
    getPopupContentByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        const { theme, title, description } = content.popupInfo
        let popupInfo = {
          title: title,
          description: description,
          cover_id: content.popupImage.id,
          theme: theme,
        }
        if (content.redirect.length) {
          const redirect = content.redirect[0]
          const { buttonName, buttonColor, fontColor, event, linkType, linkName, link, sportId } = redirect

          const redirectObj = {
            id: content.popupImage.id,
            button_name: buttonName,
            button_font_color: fontColor,
            button_color: buttonColor,
            redirect: { type: linkType, link },
          }

          if (event) {
            redirectObj.event = event
          }
          if (linkType === 'custom') {
            redirectObj.redirect.name = linkName
          }
          if (linkType === 'sport') {
            redirectObj.redirect.sport_id = sportId
          }

          popupInfo = { ...popupInfo, ...redirectObj }
        }
        if (title !== '' && description !== '') {
          return {
            ...acc,
            [language]: popupInfo,
          }
        }
        return acc
      }, {})
    },
    getPopupPayload() {
      const popupPayload = {
        name: this.form.popupName,
        segments: this.form.popupSegments,
        start_date: this.$moment(this.form.startDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
        limit: this.form.showLimit,
        displays: this.form.popupDisplays,
        is_active: this.form.isActive,
        applications: this.getApplications(),
      }
      popupPayload.localizations = this.getPopupContentByLanguage()
      return popupPayload
    },
    clearForm() {
      this.form = { ...initialForm }
      this.initializeContent()
    },
    getApplications() {
      return this.form.popupApps.map((app) => {
        return {
          application_id: app.application_id,
          min_app_version: app.min_app_version,
          max_app_version: app.max_app_version,
        }
      })
    },
    async validateApps() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validateField('popupApps', resolve)
      })
      return isValid
    },
    openAddAppModal() {
      if (this.loading) {
        return
      }
      this.addAppsModal = true
    },
    closeAddAppsModal() {
      this.addAppsModal = false
    },
    openAddSegmentsModal() {
      if (this.loading) {
        return
      }
      this.addSegmentsModal = true
    },
    closeAddSegmentsModal() {
      this.addSegmentsModal = false
    },
    handleAddUser() {
      this.closeUserModal()
      this.openEditSegmentModal()
    },
    handleAddSegment(newSegment) {
      this.closeAddSegmentsModal()
      this.segments.unshift(newSegment)
    },
    closeUserModal() {
      this.addUserModal = false
    },
    openEditSegmentModal() {
      if (this.loading) {
        return
      }
      this.editSegmentModal = true
    },
    closeEditSegmentModal() {
      this.editSegmentModal = false
    },
    openAddUserModal() {
      this.closeEditSegmentModal()
      this.addUserModal = true
    },
    changeSelectedApps(apps) {
      this.closeAddAppsModal()
      this.form.popupApps = apps
      this.modalAppsData = apps
      this.validateApps()
    },
    openAddLinkTemplatesModal() {
      if (this.loading) {
        return
      }
      this.addLinkTemplateModal = true
    },
    closeAddLinkTemplatesModal() {
      this.addLinkTemplateModal = false
    },
    redirectToPopup() {
      this.$router.push('/popup-banners')
    },
    async getPopup() {
      try {
        this.loading = true
        const popupResponse = await PopupService.getPopup(this.$route.params.id)
        this.loading = false
        return popupResponse.data.data
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async createPopup() {
      try {
        this.loading = true
        const popupPayload = this.getPopupPayload()
        const popupResponse = await PopupService.createPopup(popupPayload)
        if (popupResponse.data.data) {
          this.$notification.success({
            message: 'Pop up banner successfully created',
          })
          this.redirectToPopup()
        }
        if (popupResponse?.data?.error) {
          this.$notification.error({
            message: 'Error while creating popup',
            description: popupResponse?.data?.error?.message || '',
          })
          return
        }
      } catch (err) {
        const errorMessage =
        err.response?.data?.error?.message || err.message || 'An unexpected error occurred'

        this.$notification.error({
          message: errorMessage,
        })
        this.loading = false
      }
    },
    async updatePopup() {
      try {
        this.loading = true
        const popupPayload = this.getPopupPayload()
        const popupResponse = await PopupService.updatePopup(this.popupToEdit.id, popupPayload)
        if (popupResponse.data.data) {
          this.$notification.success({
            message: 'Pop up successfully updated',
          })
          this.redirectToPopup()
        }
        if (popupResponse?.data?.error) {
          this.$notification.error({
            message: 'Error while updating popup',
            description: popupResponse?.data?.error?.message || '',
          })
          return
        }
      } catch (err) {
        const errorMessage =
        err.response?.data?.error?.message || err.message || 'An unexpected error occurred'

        this.$notification.error({
          message: errorMessage,
        })
        this.loading = false
      }
    },
    async deletePopup() {
      try {
        this.loading = true
        const popupResponse = await PopupService.deletePopup(this.popupToEdit.id)
        if (popupResponse.data.data) {
          this.$notification.success({
            message: 'Pop-up successfully deleted',
          })
          this.redirectToPopup()
        }
        if (popupResponse?.data?.error) {
          this.$notification.error({
            message: 'Error while deleting popup',
            description: popupResponse?.data?.error?.message || '',
          })
          return
        }
      } catch (err) {
        const errorMessage =
        err.response?.data?.error?.message || err.message || 'An unexpected error occurred'

        this.$notification.error({
          message: errorMessage,
        })
        this.loading = false
      }
    },
    async handleAddTemplatesModal() {
      try {
        this.loading = true
        const templateResponse = await apiClient.post(
          '/admin/links',
          this.addTemplateForm,
        )
        this.linkTemplates.push(templateResponse.data.data)
        this.closeAddLinkTemplatesModal()
        this.$refs.templateForm.resetFields()
        if (templateResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while adding templates',
            description: templateResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding templates',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
      this.loading = false
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async fetchSports() {
      this.loading = true
      const url = '/admin/sports?results=1000'
      try {
        const response = await apiClient.get(url)
        this.sports = response.data.data
      } catch (error) {
        console.log('Error while trying to get sports', error)
      } finally {
        this.loading = false
      }
    },
    async fetchLinkTemplates() {
      this.loading = true
      const url = '/admin/links?type=custom&results=1000'
      try {
        const response = await apiClient.get(url)
        this.linkTemplates = response.data.data
      } catch (error) {
        console.log('Error while trying to get templates', error)
      } finally {
        this.loading = false
      }
    },
    async getSegments() {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegments(undefined, undefined, 1000)
        if (response.data.data) {
          this.segments = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">

.popup-form {
  gap: 1rem;

  .text {
    color: #8C8C8C;
    font-size: 12px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }
  .images {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .uploader {
    border-radius: 2px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: auto;
    position: relative;

    &.main .ant-upload-list-item,
    &.main .ant-upload-list-picture-card-container,
    &.main .ant-upload-select-picture-card {
      width: 120px !important;
      height: 190px !important;
      margin: 0 !important;

      &>span {
        padding: 0;

        &>img {
          width: 120px !important;
          height: 190px !important;
        }
      }
    }
  }

  .popup-image {
    width: 120px;
    height: 190px;

    &>img {
      width: 120px;
      height: 190px;
    }
  }
}
  &__tag-select{
    display: flex;
    gap: .5rem;
    align-items: center;
  }
}

.redirect-list {
  gap: 1rem;

  .ant-form-item {
    min-width: 200px
  }
}
.modal-title {
  font-size: 16px;
  font-weight: bold;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222e;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.text-count {
  height: 30px;
  color: #BFBFBF;
  text-align: end;
}
</style>

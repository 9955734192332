import apiClient from '@/services/axios'

class PopupService {
  async getPopups(searchParams) {
    const url = `/admin/popup-banners?${searchParams}`
    return apiClient.get(url)
  }

  async getPopup(popupId) {
    const url = `/admin/popup-banners/${popupId}`
    return apiClient.get(url)
  }

  async createPopup(data) {
    const url = '/admin/popup-banners'
    return apiClient.post(url, data)
  }

  async updatePopup(popupId, data) {
    const url = `/admin/popup-banners/${popupId}`
    return apiClient.put(url, data)
  }

  async deletePopup(popupId) {
    const url = `/admin/popup-banners/${popupId}`
    return apiClient.delete(url)
  }

  async uploadPopupImage(file) {
    const formData = new FormData()
    formData.append('cover', file.originFileObj)

    const url = '/admin/popup-banners/cover'
    return apiClient.post(url, formData)
  }

  async sortPopups(appId, data) {
    const url = `/admin/popup-banners/sort/${appId}`
    return apiClient.post(url, data)
  }
}
export default new PopupService()
